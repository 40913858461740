<template>
  <v-footer
    id="home-footer"
    color="black"
    dark
    min-height="72"
  >
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-btn
            class="white--text"
            icon
            @click="bsc"
          >
            <v-img
              height="36px"
              width="36px"
              :src="require('@/assets/bsc.png')"
            />
          </v-btn>
          <v-btn
            class="ml-4"
            icon
            @click="telegram"
          >
            <v-icon size="36px">
              mdi-telegram
            </v-icon>
          </v-btn>
          <v-btn
            icon
            class="ml-4"
            @click="twitter"
          >
            <v-icon size="36px">
              mdi-twitter
            </v-icon>
          </v-btn>
        </v-col>

        <v-col
          class="text-center text-md-right"
          cols="12"
          md="6"
        >
          Copyright &copy; 2021 Feed Every Koala
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'HomeFooter',

    data: () => ({
      social: [
        'Facebook',
        'Twitter',
        'Instagram',
        'Linkedin',
      ],
    }),
    methods: {
      bsc () {
        window.open('https://bscscan.com/token/0xcf8b87bd6c49cb0eb28b374d62fffc3922c8c77d')
      },
      twitter () {
        window.open('https://twitter.com/fektoken')
      },
      telegram () {
        window.open('https://t.me/fektoken')
      },
    },
  }
</script>

<style lang="sass">
#home-footer a
  text-decoration: none
</style>
